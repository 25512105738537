let RenewalUpgrade = function(tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr

    this.OrderList = function(startTime,endTime,pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/RenewalUpgrade/GetPages'
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.bankcardAdd = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/Bankcard'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.Edit = function (item, success, error) {
        if (item && item.id) {
            var url = ServiceBaseAddress + '/api/Bankcard';
            TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
    }
    this.bankcardRemove = function(organizationId, success, error) {
        var url =
            ServiceBaseAddress + '/api/Bankcard/?bankCardId=' + organizationId
        TokenClient.Delete(
            url,
            true,
            null,
            null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 升级续费计算
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.Calculation = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/RenewalUpgrade/Calculation'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 确认订单
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.ConfirmOrder = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/RenewalUpgrade/ConfirmOrder'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 验证用户是否存在待处理订单
     * @param item
     * @param success
     * @param error
     * @constructor
     */
     this.Verifivation = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/RenewalUpgrade/Verification'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            function (data) {
                console.log(data)
                if (error) {
                    var jsonObj = JSON.parse(data.msg)
                    error(jsonObj)
                }
            }
        )
    }
    /**
     * 取消订单
     * @param orderId
     * @param success
     * @param error
     * @constructor
     */
    this.CancelOrder = function(orderId, success, error) {
        var url = ServiceBaseAddress + '/api/RenewalUpgrade/CancelOrder/'+ orderId
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 订单详情
     * @param orderId
     * @param success
     * @param error
     * @constructor
     */
    this.OrderDetail = function(orderId, success, error) {
        var url = ServiceBaseAddress + '/api/RenewalUpgrade/'+ orderId
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.myOrderDetail = function(orderId, success, error) {
        var url = ServiceBaseAddress + '/api/Agent/ReUpdateUserDetail/'+ orderId
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.myDeleteRenewalUpgrade = function(orderId, success, error) {
        var url = ServiceBaseAddress + '/api/Agent/DeleteRenewalUpgrade/'+ orderId
        TokenClient.Delete(
            url,
            true,
            null,
            null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.FairUserAllOrder = function (keyWord, startTime, endTime, pageIndex, pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/RenewalUpgrade/FairUserAllOrder'
        if (keyWord) {
            url += `/${keyWord}`
        }
        else {
            url += '/%20'
        }
        if (startTime) {
            url += `/${startTime}`
        }
        else {
            url += '/%20'
        }
        if (endTime) {
            url += `/${endTime}`
        }
        else {
            url += '/%20'
        }
        if (pageIndex) {
            url += `/${pageIndex}`
        }
        if (pageSize) {
            url += `/${pageSize}`
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    this.FairRenewApply = function (userId, success, error) {
        var url = ServiceBaseAddress + `/api/WeChatPay/FairRenewApply?userId=${userId}`
        TokenClient.Post(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
}

export { RenewalUpgrade }

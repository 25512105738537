<template>
  <div>
    <!--      头部      -->
    <div class="iq-top-navbar">
      <el-page-header @back="goBack" content="订单详情"> </el-page-header>
      <div class="orderStatus">
        订单状态：<span class="warning" v-if="orderDetail.orderState == 0">待支付</span>
        <span class="success" v-if="orderDetail.orderState == 1">已支付</span>
        <span class="danger" v-if="orderDetail.orderState == -1">取消支付</span>
      </div>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <el-tabs v-model="activeName">
          <el-tab-pane label="订单详情" name="tabname">
            <el-descriptions class="margin-top" :column="1" border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  订单类型
                </template>
                <span v-if="orderDetail.orderType == '1'">单账号升级</span>
                <span v-if="orderDetail.orderType == '2'">多账号升级</span>
                <span v-if="orderDetail.orderType == '3'">单账号续费</span>
                <span v-if="orderDetail.orderType == '4'">多账号续费</span>
              </el-descriptions-item>
              <template v-if="orderDetail.orderType == '1'">
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-user"></i>
                    当前升级账号
                  </template>
                  {{ userAccount }}
                </el-descriptions-item>
              </template>
              <template v-if="orderDetail.orderType == '2'">
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-user"></i>
                    升级账户数量
                  </template>
                  {{ orderDetail.upgradeUsers.length }}个
                </el-descriptions-item>
              </template>
              <template v-if="orderDetail.orderType == '3'">
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-user"></i>
                    当前续费账号
                  </template>
                  {{ userAccount }}
                </el-descriptions-item>
              </template>
              <template v-if="orderDetail.orderType == '4'">
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-user"></i>
                    续费账户数量
                  </template>
                  {{ orderDetail.upgradeUsers.length }}个
                </el-descriptions-item>
              </template>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  操作人
                </template>
                {{ orderDetail.createUser.userName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  联系电话
                </template>
                {{ orderDetail.createUser.phone }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  当前剩余
                </template>
                {{ orderDetail.remainingDays }}
              </el-descriptions-item>
              <template v-if="orderDetail.orderType == '3' || orderDetail.orderType == '4'">
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-location-outline"></i>
                    续费后剩余
                  </template>
                  {{ orderDetail.renewalAfterDays }}
                </el-descriptions-item>
              </template>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-tickets"></i>
                  当前版本
                </template>
                {{ orderDetail.beforeVersionName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-tickets"></i>
                  升级后版本
                </template>
                {{ orderDetail.afterVersionName }}
              </el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
        </el-tabs>
        <el-tabs class="mt-4" v-model="activeName">
          <el-tab-pane label="支付信息" name="tabname">
            <el-descriptions class="margin-top" :column="1" border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  支付方式
                </template>
                <span v-if="orderDetail.payWay == '1'">微信</span>
                <span v-if="orderDetail.payWay == '2'">支付宝</span>
                <span v-if="orderDetail.payWay == '3'">银行卡</span>
                <span v-if="orderDetail.payWay == '0'">余额</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  支付时间
                </template>
                {{ orderDetail.paySuccessTime | timefilters }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location-outline"></i>
                  应付金额
                </template>
                ￥{{ orderDetail.sumPayable }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-tickets"></i>
                  已优惠金额
                </template>
                ￥{{ orderDetail.discountAmount }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  实付金额
                </template>
                ￥{{ orderDetail.payAmount }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  开户账号
                </template>
                1711 0143 0910 0016 304
              </el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { RenewalUpgrade } from '../../../components/HospitalDomain/RenewalUpgrade'

export default {
  name: 'OrderDetail',
  data() {
    var renewalUpgrade = new RenewalUpgrade(this.TokenClient, this.Services.Authorization)
    return {
      RenewalDomain: renewalUpgrade,
      activeName: 'tabname',
      orderId: this.$route.query.orderId, //列表页id
      orderDetail: { createUser: { userName: '' } },
      userAccount: '',
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getDetail() {
      var _this = this
      _this.RenewalDomain.OrderDetail(
        _this.orderId,
        function (data) {
          _this.orderDetail = data.data
          _this.userAccount = data.data.upgradeUsers[0].userName + '【' + data.data.upgradeUsers[0].phone + '】'
        },
        function (error) {
          console.log(error)
        }
      )
    },
  },
}
</script>

<style scoped>
.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}
.orderStatus {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.orderStatus span.success {
  font-size: 18px;
  color: #14b83e;
}
.orderStatus span.warning {
  font-size: 18px;
  color: #ff8316;
}
.orderStatus span.danger {
  font-size: 18px;
  color: #e20a0a;
}
</style>
